import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section>
      <div id="#not-found">
        <div className="relative container max-w-7xl mx-auto px-8  py-10 sm:py-24">
          <div className="grid gap-16 text-white max-w-section mx-auto">
            <div className="grid gap-8 justify-items-center text-center">
              <div className="text-center grid gap-2">
                <h2 className="text-lg font-bold uppercase tracking-widest">404</h2>
                <h3 className="text-3xl sm:text-5xl font-black p-1 text-brand-gradient">Página Não Encontrada</h3>
              </div>
              <div>
                <p className="leading-relaxed">Oops! A página que procuras não foi encontrada.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
